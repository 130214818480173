<template>
  <div class="welfare">
    <div class="welfare-title">2022年投放顾问佣金提档政策</div>
    <div class="welfare-section">
      <div class="ws-title">一. 推广公众号</div>
      <table border="1" class="ws-table ws-table-1">
        <tr>
          <th>当月有效新增/人</th>
          <th>抽佣百分比：单价</th>
          <th>收入计算/元</th>
        </tr>
        <tr>
          <td>0〜1w</td>
          <td>20%：0.2元</td>
          <td>〜2k</td>
        </tr>
        <tr>
          <td>1w〜3w</td>
          <td>25%：0.25元</td>
          <td>2.5k〜7.5k</td>
        </tr>
        <tr>
          <td>3w〜8w</td>
          <td>30%：0.3元</td>
          <td>9k〜2.4w</td>
        </tr>
        <tr>
          <td>3w〜8w</td>
          <td>30%：0.3元</td>
          <td>9k〜2.4w</td>
        </tr>
        <tr>
          <td>8w〜15w</td>
          <td>40%：0.4元</td>
          <td>3.2〜6w</td>
        </tr>
        <tr>
          <td>15w +</td>
          <td>50%：0.5元</td>
          <td>7.5w+</td>
        </tr>
      </table>
      <div class="ws-info">
        <div class="ws-info-title ws-title">
          1. 当月有效新增人数，决定次月抽佣单价。
        </div>
        <div class="ws-info-text">
          例：某投放顾问在2022年1月入驻并开启合作，当月享受初始抽佣单价0.2元，其名下所有达人在2022年1月有效新增合计达到2w人，则：<span
            class="ws-text-tips">1月收入为2w*0.2元=4k元。</span>但在次月即2022年2月享受提档抽佣单价0.25元，如在2022年2月其名下所有达人有效新增合计仍然为2w人，则：
          <span class="ws-text-tips">2月收入为2w*0.25元=5k元。</span>
        </div>
        <div class="ws-info-title ws-title">
          2. 每周三结算上周收入，节假日顺延。
        </div>
      </div>
    </div>
    <div class="welfare-section">
      <div class="ws-title">二. 推广海读小说App</div>
      <div class="ws-info">
        <div class="ws-info-title ws-text-tips">即将开启</div>
      </div>
    </div>
    <div class="welfare-section">
      <div class="ws-title">三. 补充说明</div>
      <div class="ws-info noWeight">
        <div class="ws-info-title">
          1.
          顾问自身有一定的做号经验、带人经验，有一定的沟通能力以及耐心。
        </div>
        <div class="ws-info-title">
          2.
          生成顾问后，以十天为一个初步审核周期，如顾问名下十天内拓展不到10名达人，我方将取消顾问资格。
        </div>
        <div class="ws-info-title">
          3.
          如顾问名下的大多数达人活跃度普遍较低，我方将取消顾问资格。
        </div>
        <div class="ws-info-title">
          4.
          为拓展更多可合作达人，建议投放顾问对名下达人转介绍新人的行为进行一定激励。
        </div>
        <div class="ws-info-title">
          5.
          如投放顾问有主观作弊行为（如刷量、假量等），或其他影响平台官方声誉行为（如抹黑、诋毁等），一经发现即扣除投放顾问名下全部应有收益，并拉入合作黑名单。
        </div>
        <div class="ws-info-title">
          6.
          如利用投放顾问身份及官方合作者身份，欺骗达人或其他人，谋取非法所得，一经发现即扣除投放顾问名下全部应有收益，并拉入合作黑名单。
        </div>
        <div class="ws-info-title">
          7.
          所有投放顾问应在尊重事实的基础上，积极维护平台官方品牌。无论因任何原因引起之纠纷，均应通过正当途径如双方协商、司法诉讼予以维权和申诉，但不得通过各种方式损害平台官方形象与名誉。
        </div>
        <div class="ws-info-title">8. 本政策有效期至2022年12月31日。</div>
        <div class="ws-info-title">
          9.
          平台官方对本政策拥有最终解释权，并保留根据实际情况对本政策及具体操作方式进行修订的权利。如遇不可抗力因素，平台官方有权终止本政策。
        </div>
      </div>
    </div>
    <div class="jumpContact" @click="$router.push('/contact')">
      已详细阅读，立即入驻
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  async mounted() {},
  methods: {}
}
</script>

<style scoped lang="less">
.welfare {
  overflow: hidden;
  background: white;
  padding: 0px 30px 20px;

  .welfare-title {
    margin: 50px 0px 30px;
    font-weight: bold;
    font-size: 44px;
  }
  .welfare-section {
    margin-bottom: 30px;
    font-size: 30px;
    text-align: left;
    .ws-title {
      font-weight: bold;
      margin-top: 10px;
    }
    .ws-table {
      margin: 30px 0px;
      width: 100%;
      border-color: #e8e8e8;
      font-size: 24px;
      th {
        background: #f5f4f4;
      }
      th,
      td {
        text-align: center;
        padding: 8px 2px;
      }
    }
    .ws-table-1 {
      th,
      td {
        width: 33.3%;
      }
    }
    .ws-info {
      margin-top: 20px;
      .ws-info-title {
        font-size: 30px;
        margin-bottom: 10px;
        // font-weight: bold;
      }
      .ws-info-text {
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 50px;
      }
    }
    .ws-text-tips {
      color: #ff784c;
    }
  }
  .jumpContact {
    padding: 16px 42px;
    margin-bottom: 80px;
    border-radius: 50px;
    display: inline-block;
    color: #ff8870;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    border: 2px solid #ff8870;
  }
}
.noWeight {
  .ws-info-title {
    font-weight: normal !important;
    line-height: 50px !important;
    margin-bottom: 20px !important;
  }
}
</style>
